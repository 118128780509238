import axios from 'axios';
import React, { useState } from 'react';

function Search() {
    const [inputText, setInputText] = useState(''); //defines the initial state, is local to this component
    const [submitted, setSubmitted] = useState(false); 
    const [submittedText, setSubmittedText] = useState('');  
    
    const handleInputChange = (e) => {
      setInputText(e.target.value); //reads the latest input value and updates the local state of inputText
    };

    const submitMessage = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        try {
          const apiUrl = `https://d20gt6poanq2ca.cloudfront.net/message/?user_message=${inputText}&apikey=nensihak_fashionX`;
          const response = await axios.get(apiUrl);
          console.log(response.data);
          console.log('Message sent successfully');
          setSubmittedText(inputText);
          setSubmitted(false);
          setInputText('');
        } catch (error) {
          console.error('Error sending message:', error);
          setSubmitted(false);
        }
      }; 

    return (
        <form className="search-form" onSubmit={submitMessage}>   
            <div className="search-bar">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
            <input 
                type="search" 
                id="default-search" 
                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                placeholder="Trends of 2024..." 
                value={inputText}
                onChange={handleInputChange}
                required 
            />
            <button 
                type="submit" 
                disabled={submitted}
                className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                Search
            </button>
            </div>
        </form>
    );
};

export default Search;