import './App.css';
import Search from './components/Search';

function App() {
  return (
    <div className="App">
      <div className="background-image"></div>
      <p className="main-header">Need a little help with finding this season's gems?</p>
      <Search/>
      </div>
  )
  }

export default App;
